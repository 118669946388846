const settings = {
  clinic: 'clinic',
  virtual: 'virtual',
  zoom: 'zoom',
};

const participantTypes = {
  PATIENT: 'patient',
  PROVIDER: 'provider',
  PROVIDER_ADDITIONAL: 'provider_additional',
};

const appointmentStatuses = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  NO_SHOW: 'no_show',
  RESCHEDULED: 'rescheduled',
  REMOVED: 'removed',
};

const cancellationReasons = [
  {
    id: 1,
    name: 'No Show',
  },
  {
    id: 2,
    name: 'Late cancellation',
  },
  {
    id: 3,
    name: 'Patient conflict',
  },
  {
    id: 4,
    name: 'Provider conflict',
  },
];

//
/**
 * The keys are the values in our appointments.cancellationReason field.
 * The values ore the strings that are displayed in the UI.
 * @type {{"PATIENT CANCELLED": string, "SCHEDULING ERROR": string, "SLOT HOLD (No Appt)": string, "TEST APPT ONLY": string, "PATIENT NO SHOW": string, "PATIENT+CANCELLED": string, "Provider conflict": string, "No Show": string, "PATIENT RESCHEDULED": string, "PROVIDER UNAVAILABLE": string, "Patient conflict": string}}
 */
const CANCEL_REASON_DISPLAY_MAP = {
  'No Show': 'No Show',
  'PATIENT CANCELLED': 'Patient Cancelled',
  'Patient conflict': 'Patient Conflict',
  'PATIENT NO SHOW': 'Patient No Show',
  'PATIENT RESCHEDULED': 'Patient Rescheduled',
  'PATIENT+CANCELLED': 'Patient + Cancelled',
  'Provider conflict': 'Provider Conflict',
  'PROVIDER UNAVAILABLE': 'Provider Unavailable',
  'SCHEDULING ERROR': 'Scheduling Error',
  'SLOT HOLD (No Appt)': 'Slot Hold (No Appointment)',
  'TEST APPT ONLY': 'Test Appointment Only',
};

/**
 * Status constants that are used in the UI.
 * @type {{CANCELLED: string, COMPLETED: string, SCHEDULED: string ,RESCHEDULED: string}}
 */
const STATUS_DISPLAY_MAP = {
  SCHEDULED: 'Scheduled',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  RESCHEDULED: 'Rescheduled',
};

const providerRoles = {
  DIETITIAN: 'Dietitian',
};

const visitProviderTypeConstants = {
  MEDICAL: 'Medical',
  NUTRITIONAL: 'Nutritional',
};

const visitPatientTypeConstants = {
  FIRST: 'First',
  FOLLOW: 'Follow',
};

const appointmentNameConstants = {
  NEW_PATIENT: 'NEW',
  FOLLOW_UP: 'FOLLOW',
};

const genAthenaApptTypeName = (apptTypeName, orgId) => {
  if (!apptTypeName) {
    return '';
  }
  const suffix = `-${orgId}`;
  return apptTypeName.length + suffix.length <= 30 ? `${apptTypeName}${suffix}` : `${apptTypeName.substr(0, 30 - suffix.length)}${suffix}`;
};
const appointmentLogsSource = {
  PC_SCHEDULING: 'PC scheduling',
  MOBILE_SELF_SCHEDULING: 'Mobile self-scheduling',
  LEGACY_CALENDER_WORKFLOW: 'Legacy calendar workflow',
  CRON_JOB_UPDATE: 'Cron job',
};
const appointmentDayOffset = 2;
const followUpDayOffset = 90;

module.exports = {
  settings,
  participantTypes,
  appointmentStatuses,
  STATUS_DISPLAY_MAP,
  genAthenaApptTypeName,
  cancellationReasons,
  appointmentDayOffset,
  followUpDayOffset,
  providerRoles,
  visitProviderTypeConstants,
  visitPatientTypeConstants,
  appointmentNameConstants,
  CANCEL_REASON_DISPLAY_MAP,
  appointmentLogsSource,
};
